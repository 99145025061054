<script setup lang="ts">
import type { Order } from '#gql/default'
import type { PurchaseData } from '@account/types/order'
import { ROUTE_NAMES } from '@portal/constants/routes'
import { type RouteLocationRaw } from 'vue-router'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

defineProps<Props>()

const { t } = useI18n()
const { ct } = useCinemaStrings()
const { icons } = useDesign()
const localeRoute = useLocaleRoute()

const thankYouHtmlString = `*Si has usado un "c&oacute;digo de descuento" se aplica en la compra sobre el precio que se indica en el ticket original.<br><br/>
¿Tienes alguna pregunta sobre tu compra?<br/>
Haz click <a class="underline text-secondary" href="https://www.entradas.com/faq/es_es/category/cine/?affiliate=EES" target="_blank">aquí</a> para ver nuestras preguntas más frecuentes.<br><br> Te hemos dado de alta en nuestra base de datos para que recibas comunicaciones comerciales de entradas.com, en base a nuestro interés legítimo de que conozcas otros eventos y promociones. Puedes solicitar la baja <a class="underline text-secondary" href=" http://entrad.as/baja" target="_blank">aquí</a>.`

defineOptions({
  name: 'OrderConfirmationTypeTicket',
})
</script>

<template>
  <div>
    <OrderConfirmationHeading :order />
    <div v-html="ct('ENTRADAS_NO_CONSENT_HTML_BLOCK', null, '')" />
    <OrderConfirmationCodes :order />
    <OrderConfirmationOrderOverviewTicket :order :purchase-data />
    <OrderConfirmationCancellation v-if="!order.affiliate" :order />
    <OrderConfirmationCustomNotes />

    <NuxtLink
      v-if="order.cancellationInfo.policy === 'ALLOWED'"
      :to="order.cancellationInfo.link"
      class="my-8 flex w-full px-0 md:px-8 lg:px-36"
    >
      <NuxtImg
        class="w-full"
        src="https://media-cine.entradas.com/images/custom/banner-servicio-de-cambio.v17369509930070.png"
        alt="Servicio de Cambio"
      />
    </NuxtLink>

    <div
      class="my-12 print:!text-black"
      v-html="ct('THANKYOU_H3', null, thankYouHtmlString)"
    />

    <NuxtLink
      class="flex flex-row items-center underline print:hidden"
      :to="
        localeRoute({
          name: ROUTE_NAMES.CINEMA_PROGRAM,
          params: {
            citySlug: order.cinema?.city?.urlSlug,
            cinemaSlug: order.cinema?.urlSlug,
          },
        }) as RouteLocationRaw
      "
    >
      <Icon :name="icons.CHEVRON_LEFT" class="size-5" />
      <span v-text="t('backHome', { cinema: order.cinema?.name })" />
    </NuxtLink>

    <RoktWidget
      v-if="order.roktIntegration"
      :attributes="order.roktIntegration"
    />
  </div>
</template>

<i18n>
de:
  backHome: "Zurück zur Auswahl von {cinema}"
es:
  backHome: "Volver a la selección desde {cinema}"
</i18n>
