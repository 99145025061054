<template>
  <div
    class="flex flex-col items-center space-y-8 text-center md:items-start md:text-start"
  >
    <span v-if="!hideTitle" class="text-2xl font-bold" v-text="t('title')" />

    <span class="text-base" v-text="t('subTitle')" />

    <NuxtLink
      v-if="withLink"
      :to="localeRoute({ name: ROUTE_NAMES.INDEX })"
      class="button button-primary button-base"
    >
      {{ t('backToIndex') }}
    </NuxtLink>

    <KButton
      v-else
      :text="t('buttonText')"
      @click="changeState(USER_AUTH_STATE.LOGIN)"
    />
  </div>
</template>

<script setup lang="ts">
interface Props {
  hideTitle?: boolean
  withLink?: boolean
}

withDefaults(defineProps<Props>(), {
  hideTitle: false,
  withLink: false,
})

const { t } = useI18n()
const localeRoute = useLocaleRoute()
const { changeState } = useAuthState()

defineOptions({
  name: 'AuthResetPasswordSuccess',
})
</script>

<i18n>
de:
  title: "Password erfolgreich geändert!"
  subTitle: "Dein Passwort wurde erfolgreich aktualisiert! Du kannst dich jetzt mit deinem neuen Passwort anmelden."
  backToIndex: "Zurück zur Startseite"
  buttonText: "Zurück zur Anmeldung"
es:
  title: "¡Contraseña cambiada con éxito!"
  subTitle: "¡Tu contraseña se ha actualizado correctamente! Ahora puedes iniciar sesión con tu nueva contraseña."
  backToIndex: "Volver a la página de inicio"
  buttonText: "Volver al inicio de sesión"
</i18n>
