import { URL_FILTER } from '@base/constants/url-filter'
import { IMAGE_MODIFIER } from '@base/constants/image'
import type { ImageModifier } from '#gql/default'

interface MovieListIds {
  HIGHLIGHTS: string
  NOW_PLAYING: string
  COMING_SOON: string
}

const movieListIds: MovieListIds = {
  HIGHLIGHTS: '9',
  NOW_PLAYING: '2',
  COMING_SOON: '97',
}

const filtersForGenericMoviesPage: UrlFilter[] = [
  URL_FILTER.SEARCH,
  URL_FILTER.CITY,
  URL_FILTER.PERIODS,
  URL_FILTER.GENRES,
]

const filtersForCinemaProgramPage: UrlFilter[] = [
  URL_FILTER.SEARCH,
  URL_FILTER.DATES,
  URL_FILTER.LANGUAGE,
  URL_FILTER.TECHNOLOGY,
  URL_FILTER.SHOW_GROUPS,
  URL_FILTER.CONTENT_RATINGS,
  URL_FILTER.GENRES,
  URL_FILTER.EVENT,
  URL_FILTER.MISCELLANEOUS,
]

interface AdSlot {
  [key: string]: {
    publisherId: string
    id: string
    networkId?: string
    name: string
    sizes: [number, number][]
  }
}
const adSlots: AdSlot = {
  masthead: {
    publisherId: '87568659',
    id: 'div-gpt-ad-1480521708528-5',
    name: 'ce_desktop_masthead_970x250',
    sizes: [
      [970, 250],
      [970, 90],
      [728, 90],
      [320, 50],
      [320, 100],
      [320, 480],
    ],
  },
  external: {
    publisherId: '87568659',
    networkId: '22907058791',
    name: 'desktop_externalad',
    id: 'div-gpt-ad-1731066461776-0',
    sizes: [
      [970, 250],
      [970, 90],
      [728, 90],
      [320, 100],
      [320, 50],
    ],
  },
  skin: {
    publisherId: '87568659',
    id: 'div-gpt-ad-1480521708528-6',
    name: 'ce_desktop_skin_1x1',
    sizes: [[1, 1]],
  },
}

const fullWidthHeroBlocks = false

const cinemaHeroImageModifiers: ImageModifier[] = [
  { key: IMAGE_MODIFIER.BLUR, value: '65' },
  { key: IMAGE_MODIFIER.QUALITY, value: '75' },
  { key: IMAGE_MODIFIER.FIT, value: 'crop' },
  { key: IMAGE_MODIFIER.HEIGHT, value: '600' },
]

const movieHeroImageModifiers: ImageModifier[] = [
  {
    key: IMAGE_MODIFIER.BLUR,
    value: '65',
  },
  {
    key: IMAGE_MODIFIER.QUALITY,
    value: '100',
  },
]

const legacyShowWidgetUrl =
  'cine/{cityUrlSlug}/{cinemaUrlSlug}/evento/{showUrlSlug}'

export default defineAppConfig({
  affiliateFallback: '988',
  enableWatchList: false,
  enableGooglePublisherTag: true,
  enableCinemaBannersInWidgetMode: false,
  enableNewThankYouPage: false,
  enableWidgetMode: true,
  movieListIds,
  filtersForGenericMoviesPage,
  filtersForCinemaProgramPage,
  adSlots,
  fullWidthHeroBlocks,
  cinemaHeroImageModifiers,
  movieHeroImageModifiers,
  roktAccountId: '',
  legacyShowWidgetUrl,
})
